// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$enlink-primary: mat.define-palette(mat.$indigo-palette);
$enlink-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$enlink-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$enlink-theme: mat.define-light-theme(
  (
    color: (
      primary: $enlink-primary,
      accent: $enlink-accent,
      warn: $enlink-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($enlink-theme);

@import url('./assets/lib/hljs/styles/atom-one-light.css');
@import url('../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css');
@import url('../node_modules/@fortawesome/fontawesome-free/css/all.min.css');
@import './assets/scss/app.scss';
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-material.css';
