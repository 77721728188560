$vdw-color-blue: #2b4ec0;
$vdw-color-dark-blue: #13296d;
$vdw-color-hover-blue: #2a4290;
$vdw-color-focus-blue: #6c63ff;
$vdw-color-disabled-grey: #cccccc;
$vdw-color-success-green: #008a05;
$vdw-color-error-red: #cb3c34;

$primary-color-white: #fff;
$primary-color-black: #101a43;

$primary-color-vdw-pink: #e0669e;
$primary-color-grey: #737b92;
$primary-color-vdw-gradient: linear-gradient(206.22deg, $vdw-color-blue 4.43%, #294ab7 17.35%, #1e388c 83.5%);
$primary-color-jute: #dc895b;
$primary-color-highlight-blue: #4269e2;

$functional-color-red: #fa3d3d;
$functional-color-orange: #fa8b3d;
$functional-color-green: #0fba4c;

$tint-darkblue-70: #5a6a9a;
$tint-darkblue-60: #717fa7;
$tint-darkblue-60-transparent: rgba($vdw-color-dark-blue, 0.6);
$tint-darkblue-50: #8a94b7;
$tint-darkblue-30: #b9bfd4;
$tint-darkblue-30-transparent: rgba($vdw-color-dark-blue, 0.3);
$tint-darkblue-20: #d0d4e2; // main border color
$tint-darkblue-20-transparent: rgba($vdw-color-dark-blue, 0.2);
$tint-darkblue-13-transparent: rgba($vdw-color-dark-blue, 0.13);
$tint-darkblue-10: #e8eaf1;
$tint-darkblue-6: #f1f2f6;
$tint-darkblue-2: #fafbfc;

$tint-vdw-blue-hover: #223e9a;
$tint-vdw-blue-pressed-gradient: linear-gradient(0deg, $tint-vdw-blue-hover, $tint-vdw-blue-hover), $vdw-color-blue;
$tint-vdw-blue-70: #6b84d3;
$tint-vdw-blue-60: #8095d9;
$tint-vdw-blue-50: #95a7e0;
$tint-vdw-blue-40: rgba($vdw-color-blue, 0.4);
$tint-vdw-blue-30: #c0caed;
$tint-vdw-blue-30-transparent: rgba($vdw-color-blue, 0.3);
$tint-vdw-blue-20: #d5dcf2;
$tint-vdw-blue-20-transparent: rgba($vdw-color-blue, 0.2);
$tint-vdw-blue-10: #eaeef9;
$tint-vdw-blue-10-transparent: rgba($vdw-color-blue, 0.1);
$tint-vdw-blue-6: #f3f5fc;

$tint-highlight-blue-10-transparent: rgba($primary-color-highlight-blue, 0.1);

$tint-lightblue: #9dc1ff;
$tint-lightblue-70: #bbd4ff;
$tint-lightblue-40-transparent: rgba($tint-lightblue, 0.4);
$tint-lightblue-30: #e2edff;
$tint-lightblue-10: #f6f9ff;
$tint-lightblue-6: #f9fcff;

$tint-lightest-blue-30: #e2ecff;
$tint-lightest-blue-10: #f5f9ff;

$tint-vdw-pink-10: #fcf0f6;

$tint-red-70: #fc7777;
$tint-red-50: #fd9e9e;
$tint-red-25: #fecece;
$tint-red-20: #fed8d8;
$tint-red-20-transparent: rgba($functional-color-red, 0.2);
$tint-red-13: #fee6e6;
$tint-red-10: #ffecec;
$tint-red-10-transparent: rgba($functional-color-red, 0.1);
$tint-red-6: #fff4f4;
$tint-red-5: #fff5f5;

$tint-orange-70: #fcae78;
$tint-orange-50: #fdc59e;
$tint-orange-20: #fee8d8;
$tint-orange-20-transparent: rgba($functional-color-orange, 0.2);
$tint-orange-10: #fff4ec;
$tint-orange-10-transparent: rgba($functional-color-orange, 0.1);
$tint-orange-6: #fff8f4;
$tint-orange-5: #fff9f5;

$tint-green-70: #57cf85;
$tint-green-50: #87dda6;
$tint-green-20: #cff1db;
$tint-green-10: #e7f9ee;
$tint-green-6: #f1fbf4;

$tint-pink-70: #ea94bc;
$tint-pink-50: #f0b3cf;
$tint-pink-20: #f9e0ec;
$tint-pink-10: #fcf0f6;
$tint-pink-6: #fdf6f9;

$tint-black-50: #888da1;
$tint-black-20: #cfd1d9;
$tint-black-20-transparent: rgba($primary-color-black, 0.2);

$large-box-shadow-for-cards: 0 57px 59px rgba(30, 56, 140, 0.12);
