.mat-tooltip {
  white-space: pre-line;
  word-break: break-all;
}

html,
body,
app-root {
  font-family: $mat-font-family;
  height: 100%;
  margin: 0;
  width: 100%;

  h4,
  h3,
  h2,
  h1,
  .mat-button-toggle,
  %mat-button-base,
  .mat-flat-button,
  .mat-stroked-button,
  .mat-fab,
  .mat-icon-button,
  .mat-mini-fab,
  .mat-card,
  .mat-checkbox,
  .mat-input-container,
  .mat-list,
  .mat-menu-item,
  .mat-radio-button,
  .mat-select,
  .mat-list .mat-list-item .mat-list-item-content,
  .mat-nav-list .mat-list-item .mat-list-item-content,
  .mat-simple-snackbar,
  .mat-tab-label,
  .mat-slide-toggle-content,
  .mat-toolbar,
  .mat-tooltip,
  .mat-expansion-panel-header-title,
  .mat-calendar,
  .mat-button .mat-hint,
  .mat-form-field {
    font-family: $mat-font-family;
  }
}

*:focus {
  outline: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
}

a:hover {
  text-decoration: none;
}

span[matprefix] {
  padding-right: 5px;
}

[hidden] {
  display: none !important;
}

.input-row {
  margin-bottom: 15px;
}

.header-title {
  margin-top: 0;
}

.custom-tooltip {
  text-align: center;
}

body {
  touch-action: manipulation;
  background: $tint-vdw-blue-6;
}

div.mat-checkbox-frame {
  border-color: $tint-darkblue-20;
  border-width: 1px;
}

div.mat-checkbox-inner-container {
  height: 14px;
  width: 14px;
}

.mat-card mat-divider.mat-divider {
  position: initial;
}

.mat-toolbar,
.fixed-toolbar-actions {
  background-color: $primary-color-white;
  height: $toolbar-actions-height !important;
}

.fixed-toolbar-actions {
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  box-shadow: 0 -2px 8px rgba(151, 151, 151, 0.2);
}

.main-container {
  &.collapsed {
    .fixed-toolbar-actions {
      width: calc(100% - 72px);

      @media screen and (max-width: $breakpoint-lt-sm) {
        width: 100%;
      }
    }
  }

  .fixed-toolbar-actions {
    width: calc(100% - #{$side-navigation-width});
    transition: width 200ms;
  }
}

.draggable-item {
  background-color: $primary-color-white;
  border: solid 1px $tint-darkblue-20;
  border-radius: 3px;
  height: 44px;
  margin: 16px 0;
  padding: 10px 8px;
  position: relative;

  &.selected {
    border: solid 1px $tint-darkblue-20;
    box-shadow: 0 14px 9px -8px $tint-darkblue-6;
  }

  .position {
    color: $vdw-color-dark-blue;
    font-size: 18px;
    font-weight: 600;
  }

  &:hover {
    border: solid 1px $tint-darkblue-20;
    box-shadow: 0 14px 9px -8px $tint-darkblue-6;
    cursor: pointer;
  }
}

.drag-handle {
  &:hover {
    cursor: move;
  }
}

.selected-indicator {
  background-color: $vdw-color-blue;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  height: 44px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 5px;
}

* {
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

.form-scrollable-content {
  position: relative;
  overflow: auto;
  max-height: calc(100% - #{$card-header-height} - #{$card-footer-height});

  @media (min-width: 600px) {
    &.invalid-form-scrollable-content {
      height: calc(100% - #{$card-header-height-with-warning});
    }
  }
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-width-mobile {
  @media (max-width: $breakpoint-gt-xs) {
    width: 100%;
  }
}

.full-max-width {
  max-width: 100%;
}

.full-max-height {
  max-height: 100%;
}

.full-max-height-with-toolbar {
  max-height: calc(100% - #{$toolbar-actions-height});
}

.no-padding-top {
  @media (min-width: 600px) {
    padding-top: 0 !important;
  }
}

.no-padding {
  padding: 0 !important;
}

.half-height-with-gap {
  max-height: calc(50% - 8px);
}

.label-tooltip {
  position: absolute;
  top: -24px;
  left: 0;
  width: 100%;
  height: 20px;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.no-margin {
  margin: 0 !important;
}

.overflow-auto {
  overflow: auto;
}

#toast-container.toast-top-right-with-card-header {
  top: 80px;
  right: 24px;
}

@media (max-width: 599.9px) {
  #toast-container.toast-top-right-with-card-header {
    left: 50%;
    margin-left: -173px;
  }

  #toast-container.toast-top-right {
    left: 50%;
    margin-left: -173px;
  }
}

.mat-focused .mat-form-field-required-marker {
  color: #13296d !important;
}
