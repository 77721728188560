mat-dialog-container.mat-dialog-container {
  box-shadow: 0 17px 9px rgba(30, 56, 140, 0.12);
  border-radius: 5px;
  padding: 0;

  header {
    // padding: 0 24px;
    // min-height: 68px;
    // border-bottom: 1px solid $tint-darkblue-20;

    .mat-icon {
      &.title-icon {
        width: 36px;
        height: 36px;
      }
    }

    &.dialog-header-with-close-button {
      padding: 0 18px 0 24px;
    }
  }

  .dialog-content {
    padding: 16px 24px 0 24px;
  }

  mat-dialog-actions {
    margin: 16px 24px;
    padding: 0;
    min-height: initial;

    .mat-button-base + .mat-button-base {
      margin-left: 0;
    }
  }
}

.alert-dialog {
  width: 506px;

  @media screen and (max-width: 500px) {
    max-width: 100vw !important;
  }
}

.basic-dialog-panel {
  height: 527px;
  width: 720px;

  @media screen and (max-width: 720px) {
    max-width: 100vw !important;
  }

  @media screen and (orientation: landscape) and (max-width: $breakpoint-lt-md) {
    height: calc(100% - 32px);
  }

  .mat-dialog-container > :first-child {
    height: 100%;
  }
}

.finishing-image-label-dialog,
.finishing-text-label-dialog,
.change-placeholder-parts,
.confirm-production-schedule-dialog,
.conflicts-for-production-schedule-dialog,
.changelog-dialog {
  max-height: 90vh;
  width: 800px;

  .mat-dialog-container {
    display: flex;
    overflow: unset;
  }

  @media screen and (max-width: 800px) {
    max-width: 100vw !important;
  }
}

.new-update-dialog {
  max-height: 90vh;
  width: 640px;
  max-width: 90% !important;

  .mat-dialog-container {
    display: flex;
    overflow: unset;
  }
}

.finishing-image-label-dialog,
.finishing-text-label-dialog {
  height: 100%;
}

.confirm-production-schedule-dialog {
  width: 640px;
}

.conflicts-for-production-schedule-dialog {
  width: 700px;
}

.create-textile-data-dialog {
  height: 698px;
  width: 1000px;
}

.reposition-dialog {
  position: relative !important;
}

.mat-dialog-title {
  font-family: $mat-font-family;
}

.choose-machine-type-to-add-dialog {
  @media screen and (orientation: landscape) and (max-width: $breakpoint-lt-md) {
    max-height: calc(100% - 32px);

    .mat-dialog-container {
      height: inherit;
    }
  }
}

.reposition-tooltip {
  position: relative !important;

  .arrow {
    position: absolute;
  }

  .mat-dialog-container {
    overflow: hidden;
  }

  &.arrow-bottom {
    padding-bottom: 19px;

    .mat-dialog-container {
      box-shadow: 0 20px 40px $tint-darkblue-30-transparent;
    }

    .arrow {
      transform: rotate(-90deg);
      bottom: 0;
    }
  }

  &.arrow-top {
    padding-top: 19px;

    .arrow {
      transform: rotate(90deg);
      top: 0;
    }

    .mat-dialog-container {
      box-shadow: 0 -20px 40px $tint-darkblue-30-transparent;
    }
  }

  &.arrow-left {
    .arrow {
      left: 25px;
    }
  }

  &.arrow-right {
    .arrow {
      right: 25px;
    }
  }
}

.onboarding-backdrop {
  &::before {
    width: $side-navigation-width;
  }

  &::after {
    width: calc(100% - #{$side-navigation-width});
    left: $side-navigation-width;
    background-color: $tint-vdw-blue-20-transparent;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 100%;
  }
}

.onboarding-dialog {
  width: 384px;
  min-width: 384px;

  .dialog-header {
    height: 72px;
  }
}

.texstate-onboarding-dialog {
  width: 400px;
  min-width: 400px;

  .dialog-header {
    height: 98px;
  }
}

.onboarding-dialog,
.texstate-onboarding-dialog {
  .mat-dialog-container {
    overflow: hidden;
  }
}

.cdk-overlay-dark-backdrop {
  background: $tint-black-20-transparent;
}

.transparent-backdrop {
  opacity: 0;
}

.edit-drawing-in-path .mat-dialog-container {
  padding: 0;
  overflow: hidden;

  .mat-card {
    margin-bottom: 0;
    position: initial;
  }
}

.production-schedule-errors-and-warnings-dialog {
  width: 400px;
  min-width: 400px;

  .mat-dialog-container {
    box-shadow: 0 16px 48px $tint-darkblue-30-transparent !important;
  }
}
