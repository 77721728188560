/* variables  */

svg.txc-svg {
  background: rgb(255, 255, 255);
}

$txc-svg-circle-opacity: 1;
$txc-svg-chore1-color: rgb(255, 214, 0);
$txc-svg-chore10-color: rgb(8, 242, 214);
$txc-svg-chore11-color: rgb(27, 8, 242);
$txc-svg-chore12-color: rgb(8, 242, 151);
$txc-svg-chore13-color: rgb(255, 214, 0);
$txc-svg-chore14-color: rgb(254, 84, 0);
$txc-svg-chore15-color: rgb(242, 8, 149);
$txc-svg-chore16-color: rgb(0, 252, 56);
$txc-svg-weft1-color: rgb(12, 21, 240);
$txc-svg-weft2-color: rgb(231, 8, 242);
$txc-svg-weft3-color: rgb(242, 15, 8);
$txc-svg-chore2-color: rgb(254, 84, 0);
$txc-svg-weft4-color: rgb(242, 135, 8);
$txc-svg-weft5-color: rgb(8, 242, 214);
$txc-svg-weft6-color: rgb(27, 8, 242);
$txc-svg-weft7-color: rgb(8, 242, 151);
$txc-svg-weft8-color: rgb(255, 214, 0);
$txc-svg-chore3-color: rgb(242, 8, 149);
$txc-svg-chore4-color: rgb(0, 252, 56);
$txc-svg-chore5-color: rgb(12, 21, 240);
$txc-svg-chore6-color: rgb(231, 8, 242);
$txc-svg-chore7-color: rgb(242, 15, 8);
$txc-svg-chore8-color: rgb(125, 8, 242);
$txc-svg-chore9-color: rgb(242, 135, 8);

$txc-svg-layer1-color: rgb(12, 21, 240);
$txc-svg-layer3-color: rgb(242, 15, 8);
$txc-svg-layer2-color: rgb(254, 84, 0);
$txc-svg-layer4-color: rgb(242, 135, 8);
$txc-svg-layer5-color: rgb(8, 242, 214);
$txc-svg-layer6-color: rgb(27, 8, 242);
$txc-svg-layer7-color: rgb(8, 242, 151);
$txc-svg-layer8-color: rgb(255, 214, 0);

$txc-svg-layer500-color: rgb(255, 214, 0);
$txc-svg-layer501-color: rgb(254, 84, 0);
$txc-svg-layer502-color: rgb(242, 8, 149);
$txc-svg-layer503-color: rgb(0, 252, 56);
$txc-svg-layer504-color: rgb(12, 21, 240);
$txc-svg-layer505-color: rgb(231, 8, 242);
$txc-svg-layer506-color: rgb(242, 15, 8);
$txc-svg-layer990-color: rgb(127, 127, 127);
$txc-svg-layer999-color: rgb(64, 64, 64);
$txc-svg-chore999-color: rgb(127, 127, 127);
$txc-svg-chore990-color: rgb(255, 214, 0);

$txc-svg-polygon-opacity: 0.4;
$txc-svg-stroke-width: 2;
$txc-svg-font: verdana;
$txc-svg-font-size: 9px;

text.txc-svg-chore1 {
  fill: $txc-svg-chore1-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore1 {
  fill: none;
  stroke: $txc-svg-chore1-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore1 {
  fill: none;
  stroke: $txc-svg-chore1-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore1filled {
  fill: $txc-svg-chore1-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore1filled {
  fill: $txc-svg-chore1-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore1 {
  stroke: $txc-svg-chore1-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore2 {
  fill: $txc-svg-chore2-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore2 {
  fill: none;
  stroke: $txc-svg-chore2-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore2 {
  fill: none;
  stroke: $txc-svg-chore2-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore2filled {
  fill: $txc-svg-chore2-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore2filled {
  fill: $txc-svg-chore2-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore2 {
  stroke: $txc-svg-chore2-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore3 {
  fill: $txc-svg-chore3-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore3 {
  fill: none;
  stroke: $txc-svg-chore3-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore3 {
  fill: none;
  stroke: $txc-svg-chore3-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore3filled {
  fill: $txc-svg-chore3-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore3filled {
  fill: $txc-svg-chore3-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore3 {
  stroke: $txc-svg-chore3-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore4 {
  fill: $txc-svg-chore4-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore4 {
  fill: none;
  stroke: $txc-svg-chore4-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore4 {
  fill: none;
  stroke: $txc-svg-chore4-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore4filled {
  fill: $txc-svg-chore4-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore4filled {
  fill: $txc-svg-chore4-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore4 {
  stroke: $txc-svg-chore4-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore5 {
  fill: $txc-svg-chore5-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore5 {
  fill: none;
  stroke: $txc-svg-chore5-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore5 {
  fill: none;
  stroke: $txc-svg-chore5-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore5filled {
  fill: $txc-svg-chore5-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore5filled {
  fill: $txc-svg-chore5-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore5 {
  stroke: $txc-svg-chore5-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore6 {
  fill: $txc-svg-chore6-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore6 {
  fill: none;
  stroke: $txc-svg-chore6-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore6 {
  fill: none;
  stroke: $txc-svg-chore6-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore6filled {
  fill: $txc-svg-chore6-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore6filled {
  fill: $txc-svg-chore6-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore6 {
  stroke: $txc-svg-chore6-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore7 {
  fill: $txc-svg-chore7-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore7 {
  fill: none;
  stroke: $txc-svg-chore7-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore7 {
  fill: none;
  stroke: $txc-svg-chore7-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore7filled {
  fill: $txc-svg-chore7-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore7filled {
  fill: $txc-svg-chore7-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore7 {
  stroke: $txc-svg-chore7-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore8 {
  fill: $txc-svg-chore8-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore8 {
  fill: none;
  stroke: $txc-svg-chore8-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore8 {
  fill: none;
  stroke: $txc-svg-chore8-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore8filled {
  fill: $txc-svg-chore8-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore8filled {
  fill: $txc-svg-chore8-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore8 {
  stroke: $txc-svg-chore8-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore9 {
  fill: $txc-svg-chore9-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore9 {
  fill: none;
  stroke: $txc-svg-chore9-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore9 {
  fill: none;
  stroke: $txc-svg-chore9-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore9filled {
  fill: $txc-svg-chore9-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore9filled {
  fill: $txc-svg-chore9-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore9 {
  stroke: $txc-svg-chore9-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore10 {
  fill: $txc-svg-chore10-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore10 {
  fill: none;
  stroke: $txc-svg-chore10-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore10 {
  fill: none;
  stroke: $txc-svg-chore10-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore10filled {
  fill: $txc-svg-chore10-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore10filled {
  fill: $txc-svg-chore10-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore10 {
  stroke: $txc-svg-chore10-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore11 {
  fill: $txc-svg-chore11-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore11 {
  fill: none;
  stroke: $txc-svg-chore11-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore11 {
  fill: none;
  stroke: $txc-svg-chore11-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore11filled {
  fill: $txc-svg-chore11-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore11filled {
  fill: $txc-svg-chore11-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore11 {
  stroke: $txc-svg-chore11-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore12 {
  fill: $txc-svg-chore12-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore12 {
  fill: none;
  stroke: $txc-svg-chore12-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore12 {
  fill: none;
  stroke: $txc-svg-chore12-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore12filled {
  fill: $txc-svg-chore12-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore12filled {
  fill: $txc-svg-chore12-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore12 {
  stroke: $txc-svg-chore12-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore13 {
  fill: $txc-svg-chore13-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore13 {
  fill: none;
  stroke: $txc-svg-chore13-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore13 {
  fill: none;
  stroke: $txc-svg-chore13-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore13filled {
  fill: $txc-svg-chore13-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore13filled {
  fill: $txc-svg-chore13-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore13 {
  stroke: $txc-svg-chore13-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore14 {
  fill: $txc-svg-chore14-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore14 {
  fill: none;
  stroke: $txc-svg-chore14-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore14 {
  fill: none;
  stroke: $txc-svg-chore14-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore14filled {
  fill: $txc-svg-chore14-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore14filled {
  fill: $txc-svg-chore14-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore14 {
  stroke: $txc-svg-chore14-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore15 {
  fill: $txc-svg-chore15-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore15 {
  fill: none;
  stroke: $txc-svg-chore15-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore15 {
  fill: none;
  stroke: $txc-svg-chore15-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore15filled {
  fill: $txc-svg-chore15-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore15filled {
  fill: $txc-svg-chore15-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore15 {
  stroke: $txc-svg-chore15-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore16 {
  fill: $txc-svg-chore16-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore16 {
  fill: none;
  stroke: $txc-svg-chore16-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore16 {
  fill: none;
  stroke: $txc-svg-chore16-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore16filled {
  fill: $txc-svg-chore16-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore16filled {
  fill: $txc-svg-chore16-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore16 {
  stroke: $txc-svg-chore16-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft1 {
  fill: $txc-svg-weft1-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft1 {
  fill: none;
  stroke: $txc-svg-weft1-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft1 {
  fill: none;
  stroke: $txc-svg-weft1-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft1filled {
  fill: $txc-svg-weft1-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft1filled {
  fill: $txc-svg-weft1-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft1 {
  stroke: $txc-svg-weft1-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft2 {
  fill: $txc-svg-weft2-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft2 {
  fill: none;
  stroke: $txc-svg-weft2-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft2 {
  fill: none;
  stroke: $txc-svg-weft2-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft2filled {
  fill: $txc-svg-weft2-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft2filled {
  fill: $txc-svg-weft2-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft2 {
  stroke: $txc-svg-weft2-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft3 {
  fill: $txc-svg-weft3-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft3 {
  fill: none;
  stroke: $txc-svg-weft3-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft3 {
  fill: none;
  stroke: $txc-svg-weft3-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft3filled {
  fill: $txc-svg-weft3-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft3filled {
  fill: $txc-svg-weft3-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft3 {
  stroke: $txc-svg-weft3-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft4 {
  fill: $txc-svg-weft4-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft4 {
  fill: none;
  stroke: $txc-svg-weft4-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft4 {
  fill: none;
  stroke: $txc-svg-weft4-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft4filled {
  fill: $txc-svg-weft4-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft4filled {
  fill: $txc-svg-weft4-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft4 {
  stroke: $txc-svg-weft4-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft5 {
  fill: $txc-svg-weft5-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft5 {
  fill: none;
  stroke: $txc-svg-weft5-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft5 {
  fill: none;
  stroke: $txc-svg-weft5-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft5filled {
  fill: $txc-svg-weft5-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft5filled {
  fill: $txc-svg-weft5-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft5 {
  stroke: $txc-svg-weft5-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft6 {
  fill: $txc-svg-weft6-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft6 {
  fill: none;
  stroke: $txc-svg-weft6-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft6 {
  fill: none;
  stroke: $txc-svg-weft6-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft6filled {
  fill: $txc-svg-weft6-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft6filled {
  fill: $txc-svg-weft6-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft6 {
  stroke: $txc-svg-weft6-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft7 {
  fill: $txc-svg-weft7-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft7 {
  fill: none;
  stroke: $txc-svg-weft7-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft7 {
  fill: none;
  stroke: $txc-svg-weft7-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft7filled {
  fill: $txc-svg-weft7-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft7filled {
  fill: $txc-svg-weft7-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft7 {
  stroke: $txc-svg-weft7-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-weft8 {
  fill: $txc-svg-weft8-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-weft8 {
  fill: none;
  stroke: $txc-svg-weft8-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-weft8 {
  fill: none;
  stroke: $txc-svg-weft8-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-weft8filled {
  fill: $txc-svg-weft8-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-weft8filled {
  fill: $txc-svg-weft8-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-weft8 {
  stroke: $txc-svg-weft8-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore990 {
  fill: $txc-svg-chore990-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore990 {
  fill: none;
  stroke: $txc-svg-chore990-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore990 {
  fill: none;
  stroke: $txc-svg-chore990-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore990filled {
  fill: $txc-svg-chore990-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore990filled {
  fill: $txc-svg-chore990-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore990 {
  stroke: $txc-svg-chore990-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-chore999 {
  fill: $txc-svg-chore999-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-chore999 {
  fill: none;
  stroke: $txc-svg-chore999-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-chore999 {
  fill: none;
  stroke: $txc-svg-chore999-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-chore999filled {
  fill: $txc-svg-chore999-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-chore999filled {
  fill: $txc-svg-chore999-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-chore999 {
  stroke: $txc-svg-chore999-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer500 {
  fill: $txc-svg-layer500-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer500 {
  fill: none;
  stroke: $txc-svg-layer500-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer500 {
  fill: none;
  stroke: $txc-svg-layer500-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer500filled {
  fill: $txc-svg-layer500-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer500filled {
  fill: $txc-svg-layer500-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer500 {
  stroke: $txc-svg-layer500-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer504 {
  fill: $txc-svg-layer504-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer504 {
  fill: none;
  stroke: $txc-svg-layer504-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer504 {
  fill: none;
  stroke: $txc-svg-layer504-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer504filled {
  fill: $txc-svg-layer504-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer504filled {
  fill: $txc-svg-layer504-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer504 {
  stroke: $txc-svg-layer504-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer1 {
  fill: $txc-svg-layer1-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer1 {
  fill: none;
  stroke: $txc-svg-layer1-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer1 {
  fill: none;
  stroke: $txc-svg-layer1-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer1filled {
  fill: $txc-svg-layer1-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer1filled {
  fill: $txc-svg-layer1-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer1 {
  stroke: $txc-svg-layer1-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer2 {
  fill: $txc-svg-layer2-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer2 {
  fill: none;
  stroke: $txc-svg-layer2-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer2 {
  fill: none;
  stroke: $txc-svg-layer2-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer2filled {
  fill: $txc-svg-layer2-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer2filled {
  fill: $txc-svg-layer2-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer2 {
  stroke: $txc-svg-layer2-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer3 {
  fill: $txc-svg-layer3-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer3 {
  fill: none;
  stroke: $txc-svg-layer3-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer3 {
  fill: none;
  stroke: $txc-svg-layer3-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer3filled {
  fill: $txc-svg-layer3-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer3filled {
  fill: $txc-svg-layer3-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer3 {
  stroke: $txc-svg-layer3-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer4 {
  fill: $txc-svg-layer4-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer4 {
  fill: none;
  stroke: $txc-svg-layer4-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer4 {
  fill: none;
  stroke: $txc-svg-layer4-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer4filled {
  fill: $txc-svg-layer4-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer4filled {
  fill: $txc-svg-layer4-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer4 {
  stroke: $txc-svg-layer4-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer5 {
  fill: $txc-svg-layer5-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer5 {
  fill: none;
  stroke: $txc-svg-layer5-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer5 {
  fill: none;
  stroke: $txc-svg-layer5-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer5filled {
  fill: $txc-svg-layer5-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer5filled {
  fill: $txc-svg-layer5-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer5 {
  stroke: $txc-svg-layer5-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer6 {
  fill: $txc-svg-layer6-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer6 {
  fill: none;
  stroke: $txc-svg-layer6-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer6 {
  fill: none;
  stroke: $txc-svg-layer6-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer6filled {
  fill: $txc-svg-layer6-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer6filled {
  fill: $txc-svg-layer6-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer6 {
  stroke: $txc-svg-layer6-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer7 {
  fill: $txc-svg-layer7-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer7 {
  fill: none;
  stroke: $txc-svg-layer7-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer7 {
  fill: none;
  stroke: $txc-svg-layer7-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer7filled {
  fill: $txc-svg-layer7-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer7filled {
  fill: $txc-svg-layer7-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer7 {
  stroke: $txc-svg-layer7-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer8 {
  fill: $txc-svg-layer8-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer8 {
  fill: none;
  stroke: $txc-svg-layer8-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer8 {
  fill: none;
  stroke: $txc-svg-layer8-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer8filled {
  fill: $txc-svg-layer8-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer8filled {
  fill: $txc-svg-layer8-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer8 {
  stroke: $txc-svg-layer8-color;
  stroke-width: $txc-svg-stroke-width;
}

text.txc-svg-layer999 {
  fill: $txc-svg-layer999-color;
  font-family: $txc-svg-font;
  font-size: $txc-svg-font-size;
}

circle.txc-svg-layer999 {
  fill: none;
  stroke: $txc-svg-layer999-color;
  stroke-width: $txc-svg-stroke-width;
}

polyline.txc-svg-layer999 {
  fill: none;
  stroke: $txc-svg-layer999-color;
  stroke-width: $txc-svg-stroke-width;
}

circle.txc-svg-layer999filled {
  fill: $txc-svg-layer999-color;
  fill-opacity: $txc-svg-circle-opacity;
  stroke: none;
}

polygon.txc-svg-layer999filled {
  fill: $txc-svg-layer999-color;
  fill-opacity: $txc-svg-polygon-opacity;
  stroke: none;
}

line.txc-svg-layer999 {
  stroke: $txc-svg-layer999-color;
  stroke-width: $txc-svg-stroke-width;
}
