.header {
  background: #fff;
  height: 48px;
  border-bottom: 1px solid #edf2f9;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: $zindex-navbar;
  display: flex;

  .logo {
    height: 110px;
    width: $side-nav-width;
    border-right: 1px solid $border-color;
    background: $vdw-color-dark-blue;
    border-bottom: 1px solid #909bf8;
    display: flex;
    justify-content: center;
    @include transition(all 0.2s ease);

    @media screen and (max-width: $breakpoint-lt-sm) {
      display: none;
    }

    > a {
      display: block;
      text-align: center;
    }

    &.logo-white {
      display: none;
    }

    .font-weight-bold {
      color: $white;
    }

    .font-weight-light {
      color: $white;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .nav-wrap {
    flex: 1;
    display: flex;
    justify-content: end;
    height: inherit;

    .nav-left,
    .nav-right {
      list-style-type: none;
      padding-left: 0px;
      margin-bottom: 0px;
      align-items: center;
      display: flex;

      li {
        > a,
        nz-dropdown > a {
          padding: 5px 15px;
          font-size: 18px;
          color: $body-color;
          display: block;
          @include transition(background-color 0.3s);
          border-radius: $border-radius;

          &:hover,
          &:focus,
          &:active {
            color: $brand-primary;
            background-color: rgba($brand-primary, 0.1);

            .ant-badge {
              color: $brand-primary;
            }
          }

          .ant-badge {
            color: $body-color;
            font-size: 18px;
          }
        }
      }
    }

    .nav-left {
      margin-left: 10px;

      @media screen and (max-width: $breakpoint-gt-xs) {
        margin-left: unset;
      }
    }

    .nav-right {
      padding: 8px 16px 8px 16px;
      width: 168px;
      box-shadow: #ececec 1px 0px 0px 0px inset;
      cursor: pointer;
    }

    .desktop-toggle {
      @include screen-tablet {
        display: none;
      }
    }

    .mobile-toggle {
      @include screen-tablet-above {
        display: none;
      }
    }
  }

  .ant-dropdown-link,
  .ant-dropdown-trigger {
    .anticon:not(.anticon-ellipsis) {
      font-size: 18px;
      transform: none;
    }
  }

  &.header-folded {
    .logo {
      width: $side-nav-folded;
    }
  }
}

.broadcast-message {
  cursor: pointer;
}

.avatar-container {
  height: 32px;
  width: 32px;

  .avatar-wrapper {
    border-radius: 100%;
    width: 100%;
    height: 100%;

    &.default-avatar {
      background: #cad6ff80;

      span {
        color: #6c63ff;
        font-size: 16px;
        font-weight: 400;
        user-select: none;
      }
    }

    &.avatar-url {
      box-shadow: #8691b4 0px 0px 0px 0.5px inset;

      img {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border-radius: 100%;
        object-fit: cover;
      }
    }
  }
}

.select-menu-panel {
  transform: translateX(-136px) translateY(-8px) !important;
  width: 0px !important;
  height: 0px !important;

  .mat-select-panel:not(:empty) {
    border: none !important;
    border-radius: 0px 0px 10px 10px !important;
    min-width: 168px !important;
  }
}

.select-menu-arrow {
  margin: 16px 0px 10px 8px;
}

.select-menu-icon svg {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}

.user-name {
  font-weight: 400;
  font-size: 14px;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.pop-notification {
  width: 350px;
}

.is-folded {
  @media screen and (min-width: $breakpoint-gt-xs) {
    .header {
      .logo {
        width: $side-nav-folded;
        border-bottom: unset;
        padding: 38px 20px;

        img {
          margin-right: unset !important;
        }
      }
    }
  }
}

.is-expand {
  @include screen-tablet {
    .header {
      .logo {
        width: $side-nav-width;
        border-right: 1px solid $border-color;
        display: flex;
      }
    }
  }

  @media screen and (max-width: $breakpoint-gt-xs) {
    .header {
      .logo {
        width: calc(100% - 48px);
      }
    }
  }
}

.is-primary,
.is-secondary,
.is-success,
.is-danger,
.is-custom {
  .header {
    border-bottom: 0px;

    .logo {
      border-color: transparent;

      &.logo-dark {
        display: none;
      }

      &.logo-white {
        display: block;
      }
    }

    .nav-left,
    .nav-right {
      li {
        > a,
        nz-dropdown > a {
          color: rgba($white, 0.8);

          &:hover,
          &:focus,
          &:active {
            color: $white;
            background-color: rgba($white, 0.15);

            .ant-badge {
              color: $white;
            }
          }

          .ant-badge {
            color: rgba($white, 0.8);
          }
        }
      }
    }
  }
}

@each $theme in $theme-color-loop {
  .#{nth($theme, 1)} {
    .header {
      background-color: #{nth($theme, 2)};
    }
  }
}
