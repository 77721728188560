.sa-form-field.mat-form-field,
.sa-form-icon-field.mat-form-field,
.mat-form-field {
  .mat-form-field-flex {
    padding: 0 1em !important;
    background-color: transparent;
    border: none;
    box-shadow: #8994b6 0px 0px 0px 1px inset;
    border-radius: 10px !important;

    .mat-input-element.mat-form-field-autofill-control {
      font-size: 14px;
    }

    .mat-form-field-infix {
      border-top: 18px solid transparent;
      padding: 4px 0 8px 0;

      .mat-form-field-label {
        margin-top: -0.25em;
      }

      .mat-form-field-label mat-label {
        color: $tint-darkblue-60;
        font-size: 14px;
      }

      .mat-input-element {
        color: $vdw-color-dark-blue;
        height: 20px;
        margin: 0;
      }
    }
  }

  textarea.mat-input-element {
    margin: 0;
    padding: 0;
    height: 90px;
    min-height: 90px;
    font-family: 'Source Sans Pro';
  }

  &.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-0.65em) scale(0.8);
  }

  .mat-form-field-subscript-wrapper {
    font-size: 100%;
    margin-top: 0.3em;
    top: inherit;
    padding: 0;

    .mat-error {
      font-size: 12px;
    }
  }
}

.sa-form-field.mat-form-field-invalid,
.sa-form-icon-field.mat-form-field-invalid,
.mat-form-field-invalid {
  .mat-form-field-flex {
    box-shadow: $vdw-color-error-red 0px 0px 0px 2px inset;
  }
}

fieldset[disabled] .mat-form-field,
.sa-form-field.mat-form-field-disabled,
.sa-form-icon-field.mat-form-field-disabled,
.mat-form-field-disabled {
  .mat-form-field-flex {
    background-color: transparent !important;
    box-shadow: #8994b6 0px 0px 0px 1px inset;
  }

  .mat-form-field-infix .mat-form-field-label {
    color: #8994b6;
  }

  .mat-select-arrow-wrapper {
    .mat-select-arrow {
      filter: brightness(0.5) opacity(0.5);
    }
  }

  .mat-icon-button {
    cursor: default;
  }

  .mat-icon svg path {
    fill-opacity: 50%;
  }

  input,
  textarea,
  ngx-mat-intl-tel-input .mat-button-wrapper {
    // &:-webkit-autofill {
    //   box-shadow: inset 0 0 0 100px $tint-darkblue-6 !important;
    //   border-top: none !important;
    //   -webkit-text-fill-color: $tint-darkblue-50 !important;
    // }

    color: rgba(19, 41, 109, 0.7) !important;
  }
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.65em) scale(0.75);
}

.sa-form-icon-field.mat-form-field .mat-form-field-flex {
  .mat-form-field-infix {
    margin-left: calc(26px + 1em);
  }
}

.sa-form-field.mat-form-field.mat-focused,
.sa-form-icon-field.mat-form-field.mat-focused,
.mat-form-field.mat-focused {
  .mat-form-field-flex {
    border: none;
    box-shadow: $vdw-color-focus-blue 0px 0px 0px 2px inset;
  }
}

.ant-input {
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
  color: $input-color;
  height: auto;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;

  &.ant-input:hover {
    border-color: $input-theme-color;
  }

  &:focus,
  .ant-input-focused {
    border-color: $input-theme-color;
    box-shadow: 0 0 0 2px rgba($input-theme-color, 0.2);
  }

  &.ant-input-disabled {
    &:hover,
    &:focus {
      border-color: $input-border-color;
    }
  }

  & ~ .ant-input-prefix,
  & ~ .ant-input-suffix {
    &:not(.anticon) {
      .ant-input-search-button {
        height: $select-height;
      }
    }
  }

  &:disabled {
    background-color: $input-bg-disabled;
    cursor: not-allowed;

    &:hover {
      border-color: $input-border-color;
    }
  }

  @include placeholder {
    color: $input-color-placeholder;
  }
}
.ant-input-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
  height: auto;

  & ~ .ant-input-prefix,
  & ~ .ant-input-suffix {
    &:not(.anticon) {
      .ant-input-search-button {
        height: $select-height-sm;
      }
    }
  }
}

.ant-input-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;
  height: auto;
  font-size: $input-font-size-lg;

  & ~ .ant-input-prefix,
  & ~ .ant-input-suffix {
    &:not(.anticon) {
      .ant-input-search-button {
        height: $select-height-lg;
      }
    }
  }
}

.ant-input-affix-wrapper {
  padding: $input-padding-y $input-padding-x;
  color: $input-color;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: $input-border-radius;

  &:hover {
    border-color: $input-theme-color;
  }

  &:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: $input-theme-color;
    box-shadow: 0 0 0 2px rgba($input-theme-color, 0.2);
  }
}

.ant-input-affix-wrapper-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
}

.ant-input-affix-wrapper-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;

  .ant-input-prefix,
  .ant-input-suffix {
    font-size: $input-font-size-lg;
  }
}

.ant-input-group-addon {
  border: 1px solid $input-border-color;
  background-color: $gray-lightest;
  color: $body-color;
  font-size: $font-size-base;
  padding: 0 0.625rem;
  border-radius: $border-radius;
}

textarea {
  &.ant-input {
    min-height: $textarea-min-height;
  }
}

.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
  border: 0;
}

.ant-input-disabled {
  background-color: $input-bg-disabled;
  cursor: not-allowed;

  &:hover {
    border-color: $input-border-color;
  }
}

.ant-input-affix-wrapper-disabled {
  background-color: $input-bg-disabled;
  color: rgba($input-color, 0.6);
  &:hover {
    border-color: $input-border-color;
  }
}

.ant-input-number {
  border: 1px solid $input-border-color;
  line-height: $input-line-height;
  height: $select-height;
  color: $body-color;
  font-size: $font-size-base;

  &:hover,
  &:focus {
    border-color: $input-theme-color;
  }

  &.ant-input-number-disabled {
    &:hover,
    &:focus {
      border-color: $input-border-color;
    }
  }
}

.ant-input-number {
  border-radius: $input-border-radius;
}

.ant-input-number-input {
  height: $select-height - 0.125rem;
}

.ant-input-number-lg {
  height: $select-height-lg;

  .ant-input-number-input {
    height: $select-height-lg - 0.125rem;
  }
}

.ant-input-number-sm {
  height: $select-height-sm;

  .ant-input-number-input {
    height: $select-height-sm - 0.125rem;
  }
}

.ant-input-number-handler-wrap {
  border-left: 1px solid $input-border-color;
}

.ant-input-number-handler {
  &:hover {
    .ant-input-number-handler-down-inner,
    .ant-input-number-handler-up-inner {
      color: $input-theme-color;
    }
  }

  &:active {
    background-color: $gray-lightest;
  }
}

.ant-input-number-handler-down {
  border-top: 1px solid $input-border-color;
}

.ant-input-number-focused {
  border-color: $input-theme-color;
}

.has-error {
  .ant-input {
    border-color: $input-error-color;

    &:focus,
    &:hover {
      border-color: $input-error-color;
    }

    .ant-cascader-picker-arrow,
    .ant-form-explain,
    .ant-form-split,
    .ant-input-group-addon,
    .ant-input-prefix,
    .ant-select-arrow,
    .has-feedback {
      color: $input-error-color;
    }

    .ant-calendar-picker-icon,
    .ant-picker-icon,
    .ant-time-picker-icon {
      &:after {
        color: $input-error-color;
      }
    }
  }

  &.has-feedback {
    .ant-form-item-children {
      &:after {
        color: $input-error-color;
      }
    }
  }
}

.has-warning {
  .ant-input {
    border-color: $input-warning-color;

    &:hover {
      border-color: $input-warning-color;
    }
  }

  &.has-feedback {
    .ant-form-item-children {
      &:after {
        color: $input-warning-color;
      }
    }
  }
}

.has-success {
  &.has-feedback {
    .ant-form-item-children {
      &:after {
        color: $input-success-color;
      }
    }
  }
}

.is-validating {
  &.has-feedback {
    .ant-form-item-children-icon {
      color: $loading-color;
    }
  }
}

.ant-form-item-required {
  &:before {
    color: $input-error-color;
  }
}
