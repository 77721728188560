$line-height-for-table-cell: 40px;
$line-height-for-table-header: 20px;

// .ag-root {
//   border: 1px solid $tint-darkblue-20;
//   border-radius: 3px;
// }

.ag-header-cell-label {
  cursor: pointer;
}

.ag-row {
  border-bottom: 1px solid $tint-darkblue-20;
  line-height: $line-height-for-table-cell;

  &.ag-row-first {
    border-top-width: 0;
  }

  // .ag-cell:not([aria-colindex='1']) {
  //   border-left: 1px solid $tint-darkblue-20;
  // }

  &.ag-row-hover {
    &.normal {
      background: rgba(244, 246, 252, 1);
    }

    &.warning {
      background-color: $tint-orange-10;
    }

    &.error {
      background-color: $tint-red-10;
    }
  }

  .mat-icon {
    line-height: initial;
  }

  &.warning,
  &.error {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  &.normal {
    &.ag-row-group-expanded {
      background-color: $tint-lightblue-30;
      border-bottom: 1px solid $tint-darkblue-20;
    }

    &.ag-row-level-1 {
      background-color: $tint-lightblue-6;

      &:hover {
        background-color: $tint-lightblue-10;
      }
    }

    &.disabled {
      background-color: $tint-darkblue-6;
      pointer-events: none;

      .ag-cell-value {
        color: $tint-darkblue-60;
      }
    }
  }

  &.warning {
    background-color: $tint-orange-6;

    .ag-cell {
      color: $functional-color-orange !important;

      span {
        color: $functional-color-orange !important;
      }
    }

    .ag-icon-tree-closed {
      background-image: url('/assets/icons/24px/chevron-down-warning.svg');
    }

    .ag-icon-tree-open {
      background-image: url('/assets/icons/24px/chevron-up-warning.svg');
    }

    &.ag-row-group-expanded,
    &.dark {
      background-color: $tint-orange-20;
    }

    &:hover {
      background-color: $tint-orange-10;
    }

    &.ag-row-level-1 {
      &:hover {
        background-color: $tint-orange-10 !important;
      }
    }
  }

  &.error {
    background-color: $tint-red-6;

    .ag-cell {
      color: $functional-color-red !important;

      span {
        color: $functional-color-red !important;
      }
    }

    .ag-icon-tree-closed {
      background-image: url('/assets/icons/24px/chevron-down-error.svg');
    }

    .ag-icon-tree-open {
      background-image: url('/assets/icons/24px/chevron-up-error.svg');
    }

    &.ag-row-group-expanded,
    &.dark {
      background-color: $tint-red-20;
    }

    &.ag-row-level-1 {
      &:hover {
        background-color: $tint-red-10 !important;

        .ag-cell:first-of-type {
          background-color: $tint-red-10 !important;
        }
      }
    }

    &:hover {
      background-color: $tint-red-10;
    }
  }
}

.ag-pinned-left-cols-container,
.ag-pinned-left-cols-container.ag-hidden + .ag-center-cols-clipper {
  .ag-row {
    &.error {
      &::before {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 4px;
        background-color: $functional-color-red;
      }

      &.ag-row-level-1 {
        .ag-cell:first-of-type {
          background-color: $tint-red-6;
        }
      }
    }

    &.warning {
      &::before {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 4px;
        background-color: $functional-color-orange;
      }

      &.ag-row-level-1 {
        .ag-cell:first-of-type {
          background-color: $tint-orange-6;
        }
      }
    }
  }
}

.ag-header-cell,
.ag-cell {
  padding: 0 16px;
  color: $vdw-color-dark-blue;
}

.ag-header-cell {
  font-size: 14px;
  font-weight: $font-weight-semibold;

  // &:not([aria-colindex='1']) {
  //   border-left: 1px solid $tint-darkblue-20;
  // }
}

.ag-cell {
  font-size: 14px;

  &.priority {
    & > span {
      padding: 3px 16px;
      border-radius: 50px;
      font-weight: $font-weight-semibold;
      font-size: 13px;
    }

    &.high > span {
      background: $tint-red-20;
      color: $functional-color-red;
    }

    &.medium > span {
      background: $tint-orange-20;
      color: $functional-color-orange;
    }

    &.low > span {
      background: $tint-vdw-blue-10;
      color: $primary-color-highlight-blue;
    }
  }

  .density {
    margin-left: 8px;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }

  &:not(:first-child) {
    border-left: solid 1px rgba(0, 0, 0, 0.2);
  }
}

.ag-border {
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}

.ag-header {
  line-height: $line-height-for-table-header;
  // background-color: $tint-darkblue-2;
  border-top: 2px solid rgba(0, 0, 0, 0.2) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;

  .ag-header-cell {
    &.header-wrapped-rows {
      .ag-header-cell-text {
        white-space: initial;
      }

      .sorting-arrows {
        > .mat-icon {
          top: 30px !important;
        }
      }
    }

    &.right {
      .ag-header-cell-label {
        justify-content: flex-end;
      }
    }

    &:not(:first-of-type) {
      font-weight: $font-weight-semibold;
    }

    &.capitalize {
      .ag-header-cell-text::first-letter {
        text-transform: uppercase !important;
      }
    }
  }
}

.ag-header-select-all {
  margin-right: 8px;
}

.ag-cell-focus {
  outline: none;
}

ag-grid-angular {
  font-family: $mat-font-family;
  height: 100%;
  width: 100%;
}

.ag-center-cols-container {
  background-color: $primary-color-white;
}

.ag-row-selected:not(.ag-row-group-contracted) {
  &.normal {
    background: rgba(202, 214, 255, 1);
  }

  &.error {
    background-color: $tint-red-20;
  }

  &.warning {
    background-color: $tint-orange-20;
  }
}

.ag-icon {
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
}

.ag-group-checkbox {
  height: 100%;
}

.ag-selection-checkbox {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.ag-checkbox-input {
  appearance: none;
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-image: url('/assets/icons/16px/unchecked.svg');
  vertical-align: middle;
}

.ag-checked {
  .ag-checkbox-input {
    background-image: url('/assets/icons/16px/checked.svg') !important;
  }
}

.ag-group-contracted,
.ag-group-expanded {
  margin-left: 10px !important;
  margin-right: 10px;
}

.ag-icon-tree-closed {
  background-image: url('/assets/icons/24px/chevron-down.svg');
}

.ag-icon-tree-open {
  background-image: url('/assets/icons/24px/chevron-up.svg');
}

.pattern-example {
  padding: 0;
}

.ag-row-drag {
  background-color: transparent;
  background-image: url('/assets/icons/24px/drag.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  height: 100%;
  width: 24px;
  cursor: pointer;
  margin-right: 8px;

  &.ag-invisible {
    display: none;
  }
}

.ag-layout-normal {
  &.ag-root {
    outline: 1px solid $tint-darkblue-20;
  }

  &.ag-body-viewport {
    height: unset;
  }
}

.img-loading {
  height: 60px;
}

.ag-skeleton {
  .ag-skeleton-header {
    background-color: $tint-darkblue-2;
    height: 46px;
    margin: 1px;
    width: calc(100% - 2px);
  }

  .ag-skeleton-body {
    display: flex;
    flex-direction: column;
    height: 96px;
    justify-content: space-evenly;
    padding: 0 16px;
    width: 100%;

    .ag-skeleton-row {
      background-color: $tint-vdw-blue-10;
      height: 12px;
      width: 100%;
    }
  }
}

.dragging-drawing-over-grid {
  background-color: $tint-vdw-blue-20;
  opacity: 0.3;
}

.cell-no-x-padding {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 600px) {
  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    &::after {
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      background-color: $tint-darkblue-20;
    }
  }
}

.ag-horizontal-left-spacer {
  visibility: hidden !important;
}

.ag-overlay {
  left: unset;
  position: relative;
  top: unset;
}

.ag-dnd-ghost {
  border-radius: 6px;
  box-shadow: 0 2px 28px rgba($vdw-color-dark-blue, 0.2);
  z-index: 2;
  background-color: $primary-color-white;
  height: 56px !important;
  padding: 0 16px;

  .ag-dnd-ghost-label {
    font-size: 14px;
    color: $vdw-color-dark-blue;
  }

  .ag-dnd-ghost-icon .ag-icon {
    background-color: transparent;
    background-image: url('/assets/icons/24px/drag.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    margin-right: 8px;
  }
}
