h1 {
  font-size: 32px;
  color: $vdw-color-dark-blue;
  font-weight: $font-weight-bold;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

h2 {
  font-size: 24px;
  font-weight: $font-weight-bold;
  color: $vdw-color-dark-blue;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

h3 {
  font-size: 18px;
  font-weight: $font-weight-bold;
  color: $vdw-color-dark-blue;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

h4 {
  font-size: 16px;
  color: $vdw-color-dark-blue;
  font-weight: $font-weight-bold;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

.b1 {
  font-size: 14px !important;
  color: $vdw-color-dark-blue;
  margin: 0;
}

.b2 {
  font-size: 13px;
  color: $vdw-color-dark-blue;
  margin: 0;
}

.micro {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: $font-weight-semibold;
  color: $vdw-color-dark-blue;
}

.url {
  color: $vdw-color-blue !important;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: $vdw-color-dark-blue !important;
    text-decoration: underline;
  }

  &:visited {
    color: $vdw-color-dark-blue !important;
  }

  &.error {
    color: $functional-color-red !important;
  }

  &.warning {
    color: $functional-color-orange !important;
  }

  &.disabled {
    color: $tint-darkblue-50 !important;
  }
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-regular {
  font-weight: $font-weight-regular;
}

.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-weight-black {
  font-weight: $font-weight-black;
}

.hint {
  color: $tint-darkblue-50;
}

.copyright-text {
  font-size: 11px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.italic {
  font-style: italic;
}
