mat-card.mat-card,
mat-expansion-panel.expansion-panel-as-card,
mat-expansion-panel.expansion-panel-as-card:not([class*='mat-elevation-z']),
mat-card.mat-card:not([class*='mat-elevation-z']) {
  border: 1px solid $tint-darkblue-20;
  background: $primary-color-white;
  border-radius: 6px;
  box-shadow: none;
  padding: 0;

  &.hint {
    .hint {
      color: $vdw-color-dark-blue;
      font-size: 16px;
    }
  }

  .mat-card-header {
    border-bottom: 1px solid $tint-darkblue-20;
    height: $card-header-height;
    min-height: $card-header-height;
    padding: 0 32px;

    .mat-card-avatar:not(.mat-icon-button) {
      height: 36px;
      width: 36px;

      .mat-icon {
        height: 36px;
        width: 36px;
      }
    }
  }

  .mat-card-content > article,
  .mat-card-content > footer {
    padding: 24px;
  }

  .mat-card-title {
    color: $vdw-color-dark-blue;
    font-size: 20px;
    font-weight: $font-weight-semibold;
    margin-bottom: 0;
  }
}

mat-card.mat-card.vdw-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  .vdw-card-content {
    padding: 16px 32px;
  }
}

.mat-expansion-panel-content,
.mat-expansion-panel-header {
  font-family: $mat-font-family;
}

mat-expansion-panel {
  &.mat-expansion-panel:not([class*='mat-elevation-z']):not(.expansion-panel-as-card) {
    box-shadow: none;
  }

  article.content {
    margin-top: 20px;
  }
}

.expansion-panel-additional-options {
  .mat-expansion-panel-header {
    border-bottom: 1px solid $tint-darkblue-20;

    .mat-expansion-panel-header-title {
      color: $vdw-color-dark-blue;
      font-size: 20px;
      -webkit-font-smoothing: antialiased;
    }
  }
}

span.mat-expansion-indicator::after {
  border: none;
  content: url('/assets/icons/24px/chevron-down.svg');
  transform: initial;
  height: 24px;
}

.mat-card-footer {
  border-top: 1px solid $tint-darkblue-20;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $card-footer-height;
  margin: 0 !important;
  padding: 0 32px;
}

@media screen and (max-width: $breakpoint-lt-sm) {
  .link-form-input {
    width: 100%;
  }

  .fixed-toolbar-actions {
    box-shadow: unset;
    border-top: 1px solid $tint-darkblue-20;
  }
}
