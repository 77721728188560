.mat-datepicker-content {
  box-shadow: 0 22px 80px rgba(1, 4, 21, 0.2);
  border-radius: 8px;
}

.mat-calendar {
  height: unset !important;
}

.mat-calendar-body-selected {
  background-color: $vdw-color-blue;

  &.mat-calendar-body-today {
    box-shadow: unset;
  }
}

.mat-calendar-body {
  font-size: 16px;
}

.mat-calendar-body-cell-content {
  top: 50% !important;
  height: 36px !important;
  transform: translateY(-18px) !important;

  &:not(.mat-calendar-body-selected) {
    color: $vdw-color-dark-blue;
  }
}

[aria-hidden='true'] .mat-calendar-body-label {
  display: none;
}

.mat-calendar-body-label {
  visibility: hidden;
}

.mat-calendar-table-header {
  th:not(.mat-calendar-table-header-divider) {
    padding-bottom: 12px;
    font-size: 16px;
    color: $vdw-color-dark-blue;
  }
}

mat-month-view {
  .mat-calendar-body-cell-content {
    left: 50%;
    transform: translate(-18px, -18px) !important;
    width: 36px;
  }
}

.mat-calendar-body-in-range {
  .mat-calendar-body-today {
    border-color: $tint-darkblue-2 !important;
  }

  &.mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before,
  &.mat-calendar-body-range-start::after {
    left: unset;
    right: 0;
  }
}

.mat-calendar-body-today,
.mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
  border-color: $tint-darkblue-20 !important;
}

.mat-calendar-abbr {
  text-decoration: none !important;
  cursor: unset !important;
}
