@import 'src/assets/scss/variables';

.mat-flat-button {
  width: 100%;
  min-width: 150px;
  min-height: 50px;
  color: $primary-color-white;
  background-color: $vdw-color-blue;
  border-radius: 30px !important;
  box-shadow: none !important;
  font-size: 20px;
  font-weight: 700;
  padding: 8px !important;
  line-height: 0 !important;

  &[disabled] {
    background-color: $vdw-color-disabled-grey !important;
    color: $primary-color-white !important;
  }

  &.cdk-focused,
  &:hover {
    background-color: $vdw-color-hover-blue !important;
    color: $primary-color-white !important;
  }
}

.vdw-button {
  background-color: $vdw-color-blue;
  border: 1px solid $vdw-color-blue;
  color: $primary-color-white;
  border-radius: 30px;
  height: 36px;
  line-height: 0;
  font-weight: 400;
  padding: 0 16px;
  min-width: 99px;
  place-content: center;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;

  &.vdw-primary-button {
    &[disabled] {
      background-color: $vdw-color-disabled-grey;
      color: $primary-color-white;
      border-color: $vdw-color-disabled-grey;
    }

    &:hover:not([disabled]):not(.invalid) {
      background-color: $vdw-color-hover-blue;
      border-color: $vdw-color-hover-blue;
      cursor: pointer;

      .mat-button-focus-overlay {
        background: none;
      }

      &.cdk-mouse-focused {
        background: $vdw-color-dark-blue;
      }
    }

    .mat-icon {
      svg path {
        fill: $primary-color-white;
      }
    }
  }

  &.vdw-secondary-button {
    color: $vdw-color-blue;
    background-color: $primary-color-white;
    border-color: $vdw-color-blue;

    &[disabled] {
      background-color: $primary-color-white;
      color: #939393;
      border-color: #939393;
    }

    &:hover:not([disabled]):not(.invalid) {
      background: $tint-vdw-blue-10;
      border-color: $vdw-color-blue;
      cursor: pointer;

      .mat-button-focus-overlay {
        background: none;
      }

      &.cdk-mouse-focused {
        background: $tint-vdw-blue-20;
      }
    }
  }

  &.vdw-tertiary-button {
    background-color: rgba(134, 149, 201, 1);
    border-color: rgba(134, 149, 201, 1);

    &:hover:not([disabled]):not(.invalid) {
      background: rgba(105, 117, 159, 1);
      border-color: rgba(105, 117, 159, 1);
      cursor: pointer;

      .mat-button-focus-overlay {
        background: none;
      }

      &.cdk-mouse-focused {
        background: rgba(105, 117, 159, 1);
      }
    }
  }

  .mat-icon {
    vertical-align: middle;
    width: 16px;
    height: initial;
    margin-right: 8px;
  }
}

.progress-button {
  min-width: 99px !important;
  height: unset !important;

  button {
    min-width: 99px !important;
    height: 36px !important;
  }

  .mat-flat-button.mat-primary {
    min-height: 36px !important;
    font-weight: 400 !important;
    font-size: inherit !important;
    color: $primary-color-white;
    border: 1px solid $vdw-color-blue;
    padding: 0 16px !important;

    &[disabled] {
      background-color: $vdw-color-disabled-grey !important;
      color: $primary-color-white !important;
      border-color: $vdw-color-disabled-grey;
    }
  }

  .mat-flat-button.mat-primary:hover {
    border-color: $vdw-color-hover-blue !important;

    &[disabled] {
      border-color: $vdw-color-disabled-grey !important;
    }

    .mat-button-focus-overlay {
      background: none !important;
    }

    &.cdk-mouse-focused {
      background: $vdw-color-dark-blue !important;
    }
  }
}

.mat-button-ripple.mat-button-ripple-round {
  z-index: initial;
}

.mat-stroked-button {
  width: 100%;
  min-width: 150px;
  min-height: 50px;
  color: $vdw-color-blue;
  background-color: $primary-color-white;
  border-radius: 30px !important;
  border-color: $vdw-color-blue !important;
  box-shadow: none !important;
  font-size: 20px;
  font-weight: 700;
  padding: 8px !important;
  line-height: 1.5em !important;

  &[disabled] {
    background-color: $primary-color-white;
    color: $tint-darkblue-30 !important;
    border: 1px solid $tint-darkblue-30 !important;
  }

  &:hover:not([disabled]):not(.invalid) {
    background: $tint-vdw-blue-10 !important;
    border-color: $vdw-color-blue !important;

    .mat-button-focus-overlay {
      background: none;
    }

    &.cdk-mouse-focused {
      background: $tint-vdw-blue-20 !important;
    }
  }

  &.mat-warn {
    box-shadow: none;
    background-color: $tint-red-10;
    border: 1px solid $functional-color-red !important;
    color: $functional-color-red;

    &:not([disabled]):not(.invalid):hover {
      background-color: $tint-red-20 !important;
      border: 1px solid $functional-color-red !important;

      &.cdk-mouse-focused {
        background: $tint-red-20 !important;
      }
    }
  }

  &.mat-icon-button {
    border: 1px solid rgb(0 0 0 / 25%) !important;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px !important;
    width: 36px !important;
    min-height: 36px !important;

    .mat-button-wrapper {
      line-height: 0;
    }

    .mat-icon {
      width: 16px;
      height: 16px;
      line-height: 0;
    }

    &:hover:not(.mat-button-disabled) {
      border-color: $vdw-color-focus-blue !important;

      svg path {
        fill: $vdw-color-focus-blue !important;
        stroke: $vdw-color-focus-blue !important;
      }
    }
  }
}

button[disabled].mat-icon-button {
  background: $tint-darkblue-6;

  .mat-icon path {
    fill: $tint-darkblue-30-transparent;
  }
}

.mat-form-field-suffix,
.mat-form-field-prefix {
  button[disabled].mat-icon-button {
    background: none;
  }
}

.mat-icon-button {
  &.mat-icon-action-button-sm-without-border {
    width: 24px;
    height: 24px;

    .mat-icon {
      width: 24px;
      height: 24px;
    }

    .fill-color {
      fill: $vdw-color-dark-blue;
    }

    .stroke-color {
      stroke: $vdw-color-dark-blue;
    }

    &:not([disabled]):not(.invalid):hover {
      .fill-color {
        fill: $vdw-color-blue !important;
      }

      .stroke-color {
        stroke: $vdw-color-blue !important;
      }
    }

    &:not([disabled]).cdk-mouse-focused {
      .fill-color {
        fill: $vdw-color-dark-blue;
      }

      .stroke-color {
        fill: $vdw-color-dark-blue;
      }
    }

    &[disabled] {
      .fill-color {
        fill: $tint-darkblue-20;
      }

      .stroke-color {
        fill: $tint-darkblue-20;
      }
    }
  }

  &.mat-icon-action-button-lg-with-border {
    border: 1px solid $tint-darkblue-20;
    border-radius: 3px;
    transition: border 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 280ms cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: border, background-color;
    transition-duration: 0.4s, 280ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0s, 0s;

    .mat-icon {
      border: none !important;
      width: 24px !important;
      height: 24px !important;
    }
  }

  &.mat-icon-action-button-lg-with-border,
  &.mat-icon-action-button-lg-with-border .mat-button-ripple.mat-ripple {
    height: 44px !important;
    width: 44px !important;
  }

  &.mat-icon-action-button-sm {
    border: 1px solid $tint-darkblue-20;
    width: 26px;
    height: 26px;
    border-radius: 3px;

    .mat-icon {
      width: 24px;
      height: 24px;
    }
  }

  &.mat-icon-action-button-lg-with-border,
  &.mat-icon-action-button-sm {
    &:not([disabled]):not(.invalid):not(.select-mode-active):not(.select-mode-inactive):hover {
      background: $tint-vdw-blue-6 !important;
    }

    &:not([disabled]):not(.select-mode-active):not(.select-mode-inactive):not(.disable-focus).cdk-mouse-focused {
      background: $tint-vdw-blue-20 !important;
      border-color: $vdw-color-dark-blue;
    }

    &[disabled] {
      background: $tint-darkblue-6;
      border-color: $tint-darkblue-6;
    }
  }

  &.close-btn {
    width: 24px;
    height: 24px;
  }
}

.mat-dialog-container header {
  .mat-icon-button .mat-icon {
    border: none;
  }
}

.select-mode-active {
  background: $tint-vdw-blue-10 !important;
  border-color: $vdw-color-dark-blue !important;
}

.select-mode-inactive {
  background: $primary-color-white !important;
  border-color: $tint-darkblue-20 !important;
}

.close-button {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;

  .mat-icon {
    width: 16px;
    height: 16px;
    vertical-align: unset !important;

    svg path {
      fill: #7f77ff;
    }
  }

  &:hover {
    .mat-icon svg path {
      fill: $vdw-color-blue !important;
    }
  }
}

.mat-datepicker-toggle {
  line-height: initial !important;

  .mat-icon-button {
    vertical-align: middle;
    width: unset !important;

    .mat-icon {
      width: 16px !important;
      height: 16px !important;
      line-height: 16px !important;

      svg {
        vertical-align: unset !important;
      }
    }
  }

  .mat-button-focus-overlay {
    background: transparent !important;
  }
}
