@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/font/SourceSansPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/font/SourceSansPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/font/SourceSansPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/font/SourceSansPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/font/SourceSansPro-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
