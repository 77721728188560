.common-layout {
  display: flex;
  height: 100%;
  flex-flow: column;

  .page-container {
    min-height: 100vh;
    padding-left: $side-nav-width;
    @include transition(all 0.2s ease);

    @media screen and (min-width: $breakpoint-gt-xs) {
      padding-left: $side-nav-width-laptop;
    }

    @media screen and (max-width: $breakpoint-lt-sm) {
      padding-left: 0px;
    }
    .main-content {
      padding: 64px 24px 16px 24px;
      min-height: calc(100vh - #{$footer-height});
      display: flex;
      height: 100%;
      flex-flow: column;
      min-width: 365px;

      .main-content-header {
        @media screen and (min-width: $breakpoint-gt-xs) {
          display: flex;
          align-items: center;
        }

        padding-left: 5px;

        .page-title {
          margin-bottom: 0px;
          margin-right: 20px;
          color: $gray;
          font-size: 17px;
          line-height: 1;

          @media screen and (max-width: $breakpoint-lt-sm) {
            margin-bottom: 20px;
            margin-right: 0px;
          }
        }
      }
    }
  }

  .page-header {
    margin-left: -$layout-content-gutter;
    margin-right: -$layout-content-gutter;
    margin-top: -$layout-content-gutter;
    margin-bottom: $layout-content-gutter;
    background-color: $white;
    border-bottom: 1px solid $border-color;
    padding: 25px 30px;

    &.has-tab {
      border-bottom: 0px;
      margin-bottom: 0px;
      padding-bottom: 1px;
    }

    @media screen and (max-width: $breakpoint-lt-sm) {
      margin-left: -$layout-content-gutter-sm;
      margin-right: -$layout-content-gutter-sm;
      margin-top: -$layout-content-gutter-sm;
      margin-bottom: $layout-content-gutter-sm;
    }
  }

  .page-header-tab {
    margin-left: -$layout-content-gutter;
    margin-right: -$layout-content-gutter;

    @media screen and (max-width: $breakpoint-lt-sm) {
      margin-left: -$layout-content-gutter-sm;
      margin-right: -$layout-content-gutter-sm;
    }

    .ant-tabs-nav {
      background-color: $white;
      padding-left: $layout-content-gutter;
      padding-right: $layout-content-gutter;
      margin-bottom: $layout-content-gutter;

      @media screen and (max-width: $breakpoint-lt-sm) {
        padding-left: $layout-content-gutter-sm;
        padding-right: $layout-content-gutter-sm;
        margin-bottom: $layout-content-gutter-sm;
      }
    }

    .ant-tabs-content {
      .ant-tabs-tabpane {
        padding-left: $layout-content-gutter;
        padding-right: $layout-content-gutter;

        @media screen and (max-width: $breakpoint-lt-sm) {
          padding-left: $layout-content-gutter-sm;
          padding-right: $layout-content-gutter-sm;
        }
      }
    }
  }

  &.is-folded {
    @media screen and (min-width: $breakpoint-gt-xs) {
      .page-container {
        padding-left: $side-nav-folded;
      }
    }
  }
}
