@import 'src/assets/scss/variables';

.side-nav {
  background-color: $vdw-color-dark-blue;
  border-right: 1px solid $border-color;
  height: $side-nav-height;
  position: fixed;
  top: 110px;
  width: $side-nav-width;
  z-index: $zindex-navbar;
  @include transition(all 0.2s ease);

  @media screen and (min-width: $breakpoint-gt-xs) {
    width: $side-nav-width-laptop;
  }

  @media screen and (max-width: $breakpoint-lt-sm) {
    left: -$side-nav-width;
  }

  .ant-menu {
    background-color: transparent;
    color: $menu-text;
    font-size: $font-size-lg;
    line-height: $line-height-base;

    .ant-menu-submenu-open {
      @include background-opacity($brand-primary, 0.1);
      font-weight: $font-weight-bold;
      position: relative;

      &::before {
        background-color: grey;
        bottom: 0;
        content: '';
        opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
      }

      .arrow {
        transform: rotate(-90deg);
      }
    }

    .ant-menu-item-selected {
      @include background-opacity($brand-primary, 0.1);
      font-weight: $font-weight-bold;
      position: relative;

      &::before {
        background-color: $blue;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: 4px;
      }
    }

    .nav-submenu {
      display: none;
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-submenu-open::before {
        background-color: $blue;
        opacity: 1;
      }

      .nav-menu-item {
        position: relative;

        .nav-submenu {
          color: $vdw-color-dark-blue;
          font-size: 14px;
          background-color: $white;
          border-radius: $border-radius;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          opacity: 0;
          position: absolute;
          top: 0px;
          left: 100%;
          min-width: 200px;
          width: auto;
          margin-left: 4px;
          transition: all 0.2s ease-in-out;
          visibility: hidden;

          .nav-submenu-item {
            height: 48px;
            line-height: 48px;
            padding: 0;
            margin: 0;
            cursor: default;

            &:first-child,
            .ant-menu-submenu-title {
              color: $vdw-color-dark-blue;
              padding: 0 24px;
              user-select: none;
            }

            .ant-menu-submenu-title:hover,
            .ant-menu-submenu-title:focus {
              color: $vdw-color-blue;
              font-weight: $font-weight-semibold;
              cursor: pointer;
            }
          }
        }

        &:hover {
          > .nav-submenu {
            display: block !important;
            opacity: 1;
            visibility: visible;

            .ant-menu-item-selected {
              background: none;
              font-weight: $font-weight-normal;

              &::before {
                width: 0;
              }
            }
          }
        }
      }
    }
  }

  .ant-menu-inline > .ant-menu-item,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    margin: 0;
  }

  .nav-menu-item > .ant-menu-submenu-title {
    line-height: 56px !important;
    height: 56px !important;
  }

  @media screen and (max-width: 320px) {
    .side-nav-menu {
      padding-top: unset;
    }

    .ant-menu-inline .ant-menu-item,
    .ant-menu-inline .ant-menu-submenu-title {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 350px) {
    span {
      width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 310px) {
    span {
      width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media screen and (max-width: $breakpoint-lt-sm) {
    .m-r-35 {
      margin-right: 35px !important;
    }
  }
}

.is-folded {
  .side-nav {
    width: $side-nav-folded;

    .ant-menu-inline-collapsed {
      width: $side-nav-folded - 1px;
    }
  }

  perfect-scrollbar {
    overflow: visible;

    .ps {
      overflow: visible !important;
    }

    .ps__rail-y,
    .ps__rail-x {
      display: none;
    }
  }
}

perfect-scrollbar {
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y {
    background-color: unset !important;
  }
}

.is-default {
  .mat-divider {
    margin: 0 15px;
    background-color: white;
    border-top-width: 0.1px !important;
  }
}

.is-expand {
  @include screen-tablet {
    .side-nav {
      left: 0px;
    }
  }

  @media screen and (min-width: $breakpoint-gt-xs) {
    .side-nav {
      width: calc(100% - 48px);
    }
  }
}

.is-side-nav-dark {
  .side-nav {
    background-color: $vdw-color-dark-blue;
  }
}

@each $theme in $theme-color-loop {
  .#{nth($theme, 1)} {
    &:not(.is-side-nav-dark) {
      .side-nav-menu {
        .ant-menu:not(.ant-menu-horizontal) {
          .ant-menu-item-selected {
            background: rgba(nth($theme, 2), 0.1);

            a {
              color: #{nth($theme, 2)};
            }
          }
        }

        .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left,
        .ant-menu-vertical-right {
          .ant-menu-item {
            &:after {
              border-right: 3px solid #{nth($theme, 2)};
            }
          }
        }

        .ant-menu-item,
        .ant-menu-submenu-title {
          &:active {
            background: rgba(nth($theme, 2), 0.1);
          }

          > a {
            &:hover {
              color: #{nth($theme, 2)};
            }
          }
        }

        .ant-menu-submenu-title {
          &:hover {
            color: #{nth($theme, 2)};

            .ant-menu-submenu-arrow {
              &:after,
              &:before {
                background: linear-gradient(to right, nth($theme, 2), nth($theme, 2));
              }
            }
          }
        }
      }
    }
  }
}
