mat-icon {
  &.icon-52 {
    width: 52px;
    height: 52px;
  }

  &.icon-36 {
    width: 36px;
    height: 36px;
  }

  &.icon-16 {
    width: 16px;
    height: 16px;
  }
}

.active-status,
.inactive-status,
.status-icon {
  margin-right: 5px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  width: 8px;
  min-width: 8px;
  height: 8px;
  align-self: center;
}

.active-status,
.green-status {
  background-color: rgba(124, 204, 124, 1);
  border-color: rgba(124, 204, 124, 0.3);
}

.inactive-status,
.grey-status {
  background: rgba(196, 196, 196, 1);
  border-color: rgba(196, 196, 196, 0.3);
}

.dark-grey-status {
  background: rgba(108, 117, 125, 1);
  border-color: rgba(108, 117, 125, 0.3);
}

.orange-status {
  background: rgba(247, 162, 75, 1);
  border-color: rgba(247, 162, 75, 0.3);
}

.yellow-status {
  background: rgba(251, 209, 61, 1);
  border-color: rgba(251, 209, 61, 0.3);
}

.dark-yellow-status {
  background: rgba(228, 180, 10, 1);
  border-color: rgba(228, 180, 10, 0.3);
}

.red-status {
  background-color: rgba(249, 96, 97, 1);
  border-color: rgba(249, 96, 97, 0.3);
}

.bright-yellow-status {
  background: rgba(255, 166, 41, 1);
  border-color: rgba(255, 166, 41, 0.3);
}

.dark-red-status {
  background-color: rgba(221, 0, 0, 1);
  border-color: rgba(221, 0, 0, 0.3);
}
