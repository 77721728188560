.sa-form-field.mat-form-field,
.sa-form-icon-field.mat-form-field,
.mat-form-field {
  width: 100%;

  .mat-form-field-wrapper {
    padding-bottom: 20px;
  }

  .mat-form-field-underline {
    display: none;
  }

  div.mat-form-field-prefix {
    height: 32px;
    margin: auto;
  }

  div.mat-form-field-suffix {
    height: auto;
    margin: auto;
  }

  div.mat-form-field-prefix .mat-icon,
  div.mat-form-field-suffix .mat-icon {
    width: 24px;
    height: 24px;
    cursor: default;

    svg {
      path {
        fill: rgb(19, 41, 109);
        fill-opacity: 0.8;
      }
    }
  }

  [matPrefix] .mat-icon svg,
  [matSuffix] .mat-icon svg {
    vertical-align: middle;
  }
}

.sa-hyperlink {
  color: $vdw-color-blue;
}

.sa-hyperlink:hover {
  color: $vdw-color-dark-blue;
  text-decoration-line: underline;
  text-underline-offset: 2px;
}

.sa-hyperlink.disabled,
.sa-hyperlink.disabled:hover {
  color: #2b4ec080;
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}

.mat-select-panel-wrap,
.mat-autocomplete-panel {
  .mat-active {
    background-color: $tint-lightblue-30 !important;
  }

  .mat-option-text {
    font-size: 14px !important;
  }
}

.mat-form-field {
  textarea {
    &.mat-input-element {
      margin: 0;
      height: 88px;
      line-height: 20px;
      font-family: $mat-font-family;
      font-size: 16px;
    }

    &.not-resizable {
      resize: none;
    }
  }
}

mat-form-field.mat-focused {
  // &:not(.spinning-input) {
  //   .mat-form-field-infix,
  //   .mat-form-field-suffix,
  //   .mat-form-field-prefix,
  //   .mat-form-field-flex > div:only-child {
  //     border-color: $tint-vdw-blue-70 !important;
  //   }
  // }
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0;
  top: 68px;
}

.mat-hint,
.mat-error {
  width: 100%;
  color: $vdw-color-error-red;
  font-size: 12px;
  font-weight: $font-weight-regular !important;
  text-align: right;
}

fieldset[disabled] .mat-form-field,
.mat-form-field-disabled {
  div.mat-form-field-prefix .mat-icon,
  div.mat-form-field-suffix .mat-icon {
    svg {
      path {
        fill-opacity: 0.5 !important;
      }
    }
  }

  .mat-form-field-infix .mat-form-field-label {
    color: $tint-darkblue-50;
  }

  .mat-select-arrow-wrapper {
    .mat-select-arrow {
      filter: brightness(0.5) opacity(0.5);
    }
  }

  input {
    &:-webkit-autofill {
      // box-shadow: inset 0 0 0 100px $tint-darkblue-6 !important;
      border-top: none !important;
      -webkit-text-fill-color: $tint-darkblue-50 !important;
    }
  }
}

input:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: $vdw-color-dark-blue !important;
}

fieldset[disabled] .mat-select-placeholder {
  color: $tint-darkblue-50;
}

button {
  &.invalid:not(:disabled) {
    background: $tint-red-5 !important;
    border: 1px solid $functional-color-red !important;
    color: $functional-color-red !important;
    box-shadow: unset !important;

    &:hover {
      background: $tint-red-10 !important;
    }

    &.cdk-mouse-focused {
      background: $tint-red-20 !important;
    }
  }
}

.mat-hint {
  color: $tint-darkblue-50;
}

.mat-error {
  &.button-validation {
    font-weight: 400;
    line-height: 1.125;
    margin-top: 5px;
  }

  &.form-validation-error {
    color: $vdw-color-error-red;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: right;
    width: initial;
  }
}

mat-form-field div.mat-form-field-suffix,
mat-form-field div.mat-form-field-prefix,
mat-form-field.mat-form-field-appearance-legacy div.mat-form-field-prefix,
mat-form-field.mat-form-field-appearance-legacy div.mat-form-field-suffix {
  height: 50px;
  line-height: 40px;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 16px;
  font-family: $mat-font-family;

  .mat-icon {
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
  }
}

.label-link,
.mat-form-field.mat-focused:not(.mat-form-field-invalid) .mat-form-field-label,
mat-form-field .mat-form-field-label-wrapper label,
.mat-form-field-appearance-legacy .mat-form-field-label-wrapper label {
  color: $vdw-color-dark-blue;
  font-size: 13px;
}

.link-form-input {
  > button {
    margin-top: 22px;
  }
}

.input-link {
  background: $tint-darkblue-2;
  border: 1px solid $tint-darkblue-20;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  height: 40px;
  line-height: 40px;
  margin-top: 3px;
  padding: 0 16px;
  text-decoration: underline;

  a {
    color: $vdw-color-blue;
  }
}

.mat-select-arrow-wrapper {
  .mat-select-arrow {
    border: none;
    width: 14px;
    height: 9px;
    background-image: url(/assets/icons/16px/chevron-down.svg);
    background-repeat: no-repeat;
    margin: 0;
  }
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(-25%) !important;
}

.mat-select[aria-owns] {
  .mat-select-arrow-wrapper {
    transform: scaleY(-1) translateY(2px);
  }
}

.mat-primary {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $vdw-color-dark-blue;
  }
}

.mat-option {
  background: $primary-color-white;
  color: $vdw-color-dark-blue !important;
  font-family: $mat-font-family;
  height: 30px !important;

  .mat-option-text {
    font-size: 16px;
    line-height: normal;
    color: rgba(19, 41, 109, 0.7) !important;
  }

  &:hover:not(.mat-option-disabled) {
    background: $tint-lightblue-10;
  }

  &.mat-selected {
    background-color: $tint-lightblue-30 !important;
  }
}

.mat-option,
mat-select-trigger {
  &.color-preview {
    .color {
      border-radius: 3px;
      display: inline-block;
      height: 16px;
      width: 16px;
    }

    .color,
    span {
      margin-right: 8px;
    }

    .color,
    span,
    b {
      vertical-align: middle;
    }
  }
}

.mat-select-value {
  color: inherit;

  .mat-select-placeholder,
  .mat-select-value-text {
    color: $vdw-color-dark-blue;
    font-size: 14px;
    line-height: 20px;
  }
}

.mat-select-disabled .mat-select-value-text {
  color: rgba(19, 41, 109, 0.7) !important;
}

div.mat-select-panel:not([class*='mat-elevation-z']),
div.mat-autocomplete-panel:not([class*='mat-elevation-z']) {
  background: $tint-darkblue-2;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.mat-autocomplete-panel {
  margin-top: 4px;

  .mat-option {
    mark {
      font-weight: $font-weight-semibold;
      color: $vdw-color-dark-blue;
      background-color: transparent;
    }

    .mat-option-text {
      color: $tint-darkblue-60;
    }
  }
}

div.mat-form-field-infix {
  width: initial;
}

.error-explanation {
  color: $functional-color-red;
  font-size: 16px;
}

.mat-form-field-appearance-legacy .mat-hint,
.mat-hint {
  color: $tint-darkblue-50;

  .url {
    margin-left: 4px;
  }
}

.mat-form-field-hint-spacer {
  display: none;
}

mat-slide-toggle.mat-slide-toggle {
  &:hover {
    .mat-slide-toggle-bar {
      background-color: $tint-vdw-blue-50;
    }
  }

  .mat-slide-toggle-bar {
    background-color: $tint-vdw-blue-20;
    border-radius: 20px;
    height: 32px;
    width: 56px;

    .mat-slide-toggle-thumb {
      height: 24px;
      width: 24px;
      background-color: $primary-color-white;
      box-shadow: 0 3px 4px rgba(24, 44, 112, 0.4);
    }
  }

  .mat-slide-toggle-content {
    color: $vdw-color-dark-blue;
  }

  .mat-slide-toggle-thumb-container {
    left: 4px;
    top: 4px;

    .mat-slide-toggle-ripple {
      display: none !important;
    }
  }

  &.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(24px, 0, 0);
  }

  &.mat-primary.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background-color: $vdw-color-blue;
    }

    .mat-slide-toggle-thumb {
      background-color: $primary-color-white;
    }
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(24px, 0, 0);
}

.form-field-with-label-before {
  mat-form-field-appearance-legacy .mat-form-field-wrapper,
  mat-form-field .mat-form-field-wrapper,
  .mat-form-field-appearance-legacy .mat-form-field-wrapper,
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-wrapper {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.mat-radio-button {
  .mat-radio-container {
    height: 14px;
    width: 14px;

    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      height: 14px;
      width: 14px;
    }

    .mat-radio-outer-circle {
      border-width: 1px !important;
      border-color: $tint-darkblue-20 !important;
      background-color: $tint-darkblue-2;
    }

    .mat-radio-ripple {
      display: none !important;
    }
  }

  .mat-radio-label-content {
    color: $vdw-color-dark-blue;
  }

  &.mat-radio-checked {
    .mat-radio-inner-circle {
      transform: scale(0.4289) !important;
    }

    &:not(.mat-radio-disabled) {
      .mat-radio-inner-circle {
        background: $vdw-color-blue;
      }
    }
  }
}

.mat-checkbox-checkmark-path {
  stroke-linecap: round;
}

.mat-select-placeholder {
  color: $vdw-color-dark-blue;
}

mat-select {
  &.select-with-none-option {
    .mat-select-placeholder {
      -webkit-text-fill-color: unset;
      color: $tint-darkblue-50 !important;
    }
  }
}

.warning-icon {
  margin-right: 8px !important;
}

.mat-select-panel {
  border: 1px solid #c9d0e8 !important;
  transform: translateX(1px) translateY(42px) !important;
  min-width: calc(100% + 29px) !important;

  &.searchable-select-panel {
    transform: translateX(17px) !important;
  }

  &.multi-select-panel {
    transform: translateX(25px) !important;
  }
}

.view-mode-field {
  height: 44px;

  &.mat-form-field.mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      padding: 0;
    }
  }
}

.mat-chip-list {
  display: flex !important;

  .mat-chip-list-wrapper {
    .mat-chip {
      border: 1px solid $tint-darkblue-20 !important;
      color: $vdw-color-dark-blue !important;
      font-size: 14px !important;
      background: $tint-darkblue-6 !important;
      font-weight: $font-weight-semibold;
      min-height: 26px !important;

      .mat-chip-remove {
        width: 16px !important;
        height: 16px !important;
        opacity: 1;

        &:hover {
          opacity: 1;
        }
      }

      &.mat-standard-chip {
        &:focus::after,
        &:hover::after {
          opacity: 0 !important;
        }

        &:not(.mat-chip-disabled):active {
          box-shadow: none;
        }
      }

      &:hover {
        background-color: $tint-darkblue-10 !important;
      }

      &:last-child {
        margin-right: 34px;
      }
    }
  }
}
