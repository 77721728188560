.mobile-header {
  background: linear-gradient(108.93deg, $vdw-color-blue 2.19%, $vdw-color-dark-blue 92.91%);
  padding: 0 16px;
  height: $mobile-header-height;
  position: relative;

  .title {
    color: $primary-color-white;
    height: unset;
    width: unset;
    line-height: $mobile-header-height;
  }

  .action-button {
    width: 26px;
    height: 26px;
    position: absolute;

    &.action-button-left {
      left: 16px;
    }

    &.action-button-right {
      right: 16px;
    }

    .mat-icon {
      path {
        fill: $primary-color-white;
      }
    }
  }
}

article.mobile-content {
  padding: 16px;
  background-color: $primary-color-white;
}

@media screen and (max-width: $breakpoint-lt-sm) {
  // .mat-form-field .mat-error {
  //   display: none;
  // }
}
